<template>
    <div :style="cssVars" class="top-div">
        <div style="position: absolute; left: -1000px; right: -1000px; height: 100%; background-color: #EAF3F9;">
            <div style="position: absolute; bottom: 0; left: 0; width: 100%; height: 80px; background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BlueBackground',
        props:{
            height: {
                type: Number,
                default: 260
            }
        },
        computed: {
            cssVars() {
                return {
                    '--height': `${this.height}px`
                }
            }
        }
    }
</script>

<style scoped>
.top-div{
    position: absolute; 
    width: 100%; 
    z-index: -1;
    height: var(--height);
}

</style>