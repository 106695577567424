<template>
    <b-overlay :show='showLoader'>
        <div v-if="service">
            <b-row v-if="!service.active">
                <b-col>
                    <b-alert variant="danger" class="p-1 text-center w-100" show><b>Ta storitev ni aktivna! Vidijo jo lahko samo administratorji, navadni uporabniki do nje nimajo dostopa!</b></b-alert>
                </b-col>
            </b-row>
            <BlueBackground style="top: 4rem;"/>
            <div class="h-100 w-100 position-relative d-none d-md-block" >
                <div class="position-relative p-2" style="z-index: 3;">
                    <div class="title-section">
                        <h1 class="title">{{service.title}} </h1>
                        <b-img class="ml-2 mb-2" style="position: absolute; bottom: 2rem;" :src="require('@/assets/svg/arrow-down.svg')"/>
                    </div>
                </div>
                <b-img class="main-photo" :src="'/api/user/v1/image/' + service.main_photo"/>
            </div>

            <div class="position-relative d-md-none" style="z-index: 2 !important;">
                <div class="title-section-mobile">
                    <h1 class="title-mobile">{{service.title}}</h1>
                </div>
                <div class="d-flex justify-content-center" style="z-index: 3 !important;">
                    <b-img style="max-height: 500px" fluid :src="'/api/user/v1/image/' + service.main_photo"/>
                </div>
            </div>
            <b-row>
                <b-col cols="12" md="10" offset-md="1">
                    <div class="mt-5 mt-md-3" style="font-size: 17px;">
                        <!-- .replace(/<(?!br\s*\/?)[^>]+>/g, '') -->
                        <div class="text-instructions html-display" v-html="service.content"/>
                    </div>
                    <b-row class="mt-2" v-if="photos.length > 0">
                        <b-col cols="12" class="d-flex justify-content-center">
                            <b-img style="object-fit: cover;" fluid :src="'/api/user/v1/image/' + photos[0]"/>
                        </b-col>
                        <b-col cols="12" md="6" class="mt-1" v-for="(item, index) of photos" :key="'photo_'+ index">
                            <b-img v-if="photos.length !== index + 1" style="width: 100%; object-fit: cover;" fluid :src="'/api/user/v1/image/' + photos[index + 1]"/>
                        </b-col>
                    </b-row>
                    <div  class="instructions my-2 pb-1">
                        <div style=" padding: 5px 10px; background: #72A5D8;">
                            <div style="color: white; font-weight: bold;">NAVODILA ZA KORIŠČENJE</div>
                        </div>
                        <b-row class="p-1" v-if="$store.state.user.loggedIn">
                            <b-col cols="12" class="mt-2">
                                <div class="text-instructions p-2 html-display" v-html="service.instructions"/>
                            </b-col>
                            <b-col cols="12" class="mt-2 contact text-center">
                                <h3 class="font">Podatki o ponudniku:</h3>
                                <div class="d-md-flex align-items-center justify-content-center">
                                    <div>
                                        <p>{{provider.name}}</p>
                                        <p>{{provider.address}}</p>
                                        <p>{{provider.post_number}} {{provider.post}}</p>
                                    </div>
                                    <div class="mt-1 mt-md-0 ml-md-3 text-left">
                                        <p v-if="provider.website !== ''"><span class="font">W</span> {{provider.website}}</p>
                                        <p><span class="font">T</span> {{provider.telephone_number}}</p>
                                        <p><span class="font">E</span> {{provider.email}}</p>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <div v-if="$store.state.user.loggedIn && service.usage_type === 1" cols="12" class="mt-1 d-flex justify-content-center">
                            <rounded-button v-if="coupon === ''" font-size="14px" @click="getCoupon">PRIDOBI SVOJ KUPON UGODNOSTI</rounded-button>
                            <div v-else class="d-flex align-items-center m-0">
                                <h1 class="font text-secondary m-0">{{coupon}}</h1>
                                <rounded-button class="ml-1 h-100" font-size="18px" @click="copy(coupon)"><feather-icon icon="ClipboardIcon" size="16"/></rounded-button>
                            </div>
                        </div>
                    </div>
                   
                    <div v-if="$store.state.user.loggedIn" class="my-4">
                        <div v-if="service.usage_type === 0" class="mt-5 d-flex justify-content-center">
                            <Contact class="col-12 col-md-8 col-lg-6 p-0" :contactData="provider.contact_person" type="service" />
                        </div>
                        
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="d-flex justify-content-center align-items-center" :class="{ 'my-3': !$store.state.user.loggedIn}">
            <a class="d-flex justify-content-center align-items-center" @click="$router.push('/storitve'); $scrollToTop()">
                <div class="mr-2 text-secondary" style="font-weight: 700;font-size: 17px;">Vse storitve</div>
                <img :src="require('@/assets/svg/circle-arrow-right.svg')"/>
            </a>
        </div>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BAlert, BImg} from 'bootstrap-vue'
    import Contact from '../../Components/Contact'
    import RoundedButton from '@/views/Components/RoundedButton'
    import BlueBackground from '@/views/Components/BlueBackground'
    export default {
        components: {
            BlueBackground,
            RoundedButton,
            BCol,
            BRow,
            BOverlay,
            BAlert,
            BImg,
            Contact
        },
        data() {
            return {
                showLoader: false,
                service: null,
                serviceIndex: 0,
                photos: [],
                coupon: '',
                provider: []
            }
        },
        methods: {
            setBreadcrumbTitle(title) {
                const breadCrumbIndex = this.$route.meta.breadcrumb.findIndex(x => x.isCustomBreadcrumb)

                if (breadCrumbIndex !== -1) {
                    this.$route.meta.breadcrumb[breadCrumbIndex].text = title

                    this.$root.$emit('force-update-breadcrumbs')
                }
            },
            async loadData() {
                this.showLoader = true

                try {
                    await this.loadService()
                    await this.getServiceProvider()

                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }

            },
            async loadService() {
                const thisIns = this
                this.photos = []

                this.$scrollToTop()
                try {
                    const service = await thisIns.$http.get(`/api/user/v1/service/${this.serviceIndex}`)
                    this.service = service.data

                    for (const item of this.service.additional_photos) {
                        if (item !== this.service.main_photo) this.photos.push(item)
                    }
                    this.setBreadcrumbTitle(this.service.title)
                } catch (error) {
                    thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                }
            },
            async getServiceProvider() {
                const thisIns = this

                await thisIns.$http.get(`/api/user/v1/service/provider/${this.service.service_provider}`)
                    .then(res => {
                        this.provider = res.data
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                    })
            },
            getCoupon() {
                const thisIns = this
                thisIns.showLoader = true

                thisIns.$http.get(`/api/user/v1/service/coupon/${this.service.id}`)
                    .then(res => {
                        this.coupon = res.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            async copy(val) {
                try {
                    await navigator.clipboard.writeText(val)
                    this.$printSuccess('Kupon je bil kopiran')
                } catch ($e) {
                    this.$printError('Kupon ni bil kopiran')
                }
            }
        },
        async mounted() {
            this.serviceIndex = this.$route.params.service_id
            await this.loadData()
        },
        beforeRouteLeave(to, from, next) {
            // before leaving service page, reset breadcrumb title to empty
            this.setBreadcrumbTitle('')
            next()
        }
    }
</script>

<style>
    .text-instructions ul li{
        margin-left: 55px !important;
    }

</style>

<style scoped>
    

.font {
  font-weight: bold;
}
.contact p {
  margin-bottom: 0;
}
.main-photo {
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}
.title-section{
  background-color: #72A5D8;
  min-height: 400px;
  height: auto;
  padding-bottom: 95px;
  max-width: 35%;
  z-index: 3;
}
.title-section-mobile {
  position: absolute;
  background-color: #72A5D8;
  bottom: -3.5rem;
  z-index: 3;
}
.title{
  padding: 1.5rem;
  padding-bottom: 2rem;
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
  color: white;
}
.title-mobile{
  padding: 1rem;
  font-weight: 700;
  font-size: 1.4rem;
  color: white;
}
.instructions {
    font-size: 17px;
    background: #F3F3F3 !important;
  /* border: 4px solid #72A5D8;
  border-radius: 42px !important; */
}
</style>